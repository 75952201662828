/* home.scss */

.search-icon {
    background: url('../images/search-icon.png') no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 18px;
    position: absolute;
    right: 0;
    top: 25px;
    transition: 0.25s;
    width: 18px;

    @media (max-width: $bp-large) {
        display: none;
    }
}

.search-bar {
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    input {
        background: $white;
        border: 0;
        font-size: 3em;
        font-weight: $extra-light;
        height: 100%;
        padding-left: 20px;
        width: 100%;
        z-index: 10;
    }
}

.three-column-section {
    h3 {
        color: $med-grey;
        font-size: 1em;
        font-weight: normal;
        text-transform: uppercase;
    }

    .third-with-margin {
        margin-bottom: 20px;
    }

    p {
        color: $dark-grey;
    }
}

.section.side-by-side {
    font-weight: normal;
    padding-bottom: 0;

    .event {
        background: $cream-grey;

        li {
            list-style: none;
            padding: 5px 0;
        }

        a {
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    .item {
        padding: 50px 100px;
    }

    h2 {
        font-weight: $light;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 1.375em;
        font-weight: 300;
    }

    p {
        font-weight: normal;
    }

    a {
        clear: both;
        display: block;
        font-size: 0.9em;
        margin-bottom: 35px;
        margin-top: 35px;
    }

    @media (max-width: $bp-large) {
        .item {
            padding: 30px 20px;
        }
    }
}

.section.thumbnail-links {
    h2 {
        margin: 20px auto;
    }

    ul {
        margin: 0 auto;
        padding: 20px 0 0;
        text-align: center;

        li {
            display: inline-block;
            list-style: none;
            margin: 0 1% 0.5em;
            width: 30%;

            @media (max-width: $bp-large) {
                display: block;
                margin: 0 0 0.5em;
                width: 100%;
            }
        }

        a,
        a:visited {
            background: $black-10;
            color: $dark-grey;
            display: block;
            height: 88px;
            overflow: hidden;
            position: relative;

            &:hover {
                background: darken($black-10, 5%);
            }
        }

        .image {
            background: $black-20;
            display: block;
            float: left;
            height: 88px;
            margin-right: 1em;
            overflow: hidden;
            width: 88px;

            img {
                height: auto;
                width: 100%;
            }
        }

        .text {
            display: block;
            float: left;
            font-size: 1.125em;
            font-weight: $medium;
            left: 50%;
            margin-left: 35px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);

            @media (max-width: $bp-large) {
                float: none;
                margin: 0;
            }
        }
    }
}
